// =======================================
//   List of variables for Preset color
// =======================================
$brand-color1: linear-gradient(83.31deg, #1de9b6 21.22%, #1dc4e9 88.54%);
$brand-color2: linear-gradient(206.48deg, #a389d4 11.14%, #899ed4 104.6%);
$brand-color3: linear-gradient(207.92deg, #0398f2 11.42%, #38b9e7 106.55%);
$brand-color4: linear-gradient(162.57deg, #c5ecf9 11.95%, #c4b4e4 97.32%);

// Gray color
$white: #ffffff;
$gray-100: #f8f9fa;
$gray-200: #f3f5f7;
$gray-300: #dbe0e5;
$gray-400: #bec8d0;
$gray-500: #8996a4;
$gray-600: #5b6b79;
$gray-700: #3e4853;
$gray-800: #1d2630;
$gray-900: #131920;
$black: #000000;

// blue color
$blue-100: #b4e5fc;
$blue-200: #82d4fa;
$blue-300: #4fc3f8;
$blue-400: #2ab6f7;
$blue-500: #04a9f5;
$blue-600: #03a2f4;
$blue-700: #0398f2;
$blue-800: #028ff0;
$blue-900: #017eee;

// indigo color
$indigo-100: #d1b7fb;
$indigo-200: #b388f9;
$indigo-300: #9458f6;
$indigo-400: #7d34f4;
$indigo-500: #6610f2;
$indigo-600: #5e0ef0;
$indigo-700: #530cee;
$indigo-800: #4909ec;
$indigo-900: #3805e8;

// purple color
$purple-100: #d1c4e9;
$purple-200: #b39ddb;
$purple-300: #9575cd;
$purple-400: #7e58c2;
$purple-500: #673ab7;
$purple-600: #5f34b0;
$purple-700: #542ca7;
$purple-800: #4a259f;
$purple-900: #391890;

$pink-100: #f8c5dd;
$pink-200: #f49fc6;
$pink-300: #ef78af;
$pink-400: #eb5b9d;
$pink-500: #e83e8c;
$pink-600: #e53884;
$pink-700: #e23079;
$pink-800: #de286f;
$pink-900: #d81b5c;

$red-100: #f99b94;
$red-200: #f88077;
$red-300: #f76e64;
$red-400: #f55247;
$red-500: #f44236;
$red-600: #f21c0d;
$red-700: #da190c;
$red-800: #c1160b;
$red-900: #a9130a;

$orange-100: #fed8b9;
$orange-200: #febf8a;
$orange-300: #fea55b;
$orange-400: #fd9137;
$orange-500: #fd7e14;
$orange-600: #fd7612;
$orange-700: #fc6b0e;
$orange-800: #fc610b;
$orange-900: #fc4e06;

$yellow-100: #f8dc86;
$yellow-200: #f7d56e;
$yellow-300: #f6ce55;
$yellow-400: #f5c73d;
$yellow-500: #f4c22b;
$yellow-600: #f2b90d;
$yellow-700: #daa70b;
$yellow-800: #c2950a;
$yellow-900: #aa8209;

$green-100: #bbf8e9;
$green-200: #8ef4db;
$green-300: #61f0cc;
$green-400: #3fecc1;
$green-500: #1de9b6;
$green-600: #1ae6af;
$green-700: #15e3a6;
$green-800: #11df9e;
$green-900: #0ad98e;

$teal-100: #b3d9d9;
$teal-200: #80c0c0;
$teal-300: #4da6a6;
$teal-400: #269393;
$teal-500: #008080;
$teal-600: #007878;
$teal-700: #006d6d;
$teal-800: #006363;
$teal-900: #005050;

$cyan-100: #c5ecf9;
$cyan-200: #9fdff5;
$cyan-300: #78d2f0;
$cyan-400: #5bc9ed;
$cyan-500: #3ebfea;
$cyan-600: #38b9e7;
$cyan-700: #30b1e4;
$cyan-800: #28a9e1;
$cyan-900: #1b9bdb;
