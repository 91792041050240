// ============================
//    10. Widget css start
// ============================
.statistics-card-1 {
  .card-body {
    position: relative;
    overflow: hidden;
    > * {
      position: relative;
      z-index: 5;
    }
    .img-bg {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
  }
}

.earning-card {
  .nav {
    background: rgba(255, 255, 255, 0.3);
    border-radius: var(--bs-border-radius);
    .nav-link {
      color: #fff;
      margin: -8px 0;
      padding-top: 16px;
      padding-bottom: 16px;
      @include media-breakpoint-down(sm) {
        padding: 14px 6px;
      }
    }
    &.nav-pills {
      .nav-link.active,
      .show > .nav-link {
        box-shadow: var(--pc-card-box-shadow);
        background: var(--bs-primary-bg-subtle);
        color: var(--bs-primary);
      }
    }
  }
}

.table-card .card-body,
.table-body.card-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;

  .table {
    > thead > tr > th {
      border-top: 0;
    }

    tr {
      td,
      th {
        &:first-child {
          padding-left: 25px;
          @include media-breakpoint-down(sm) {
            padding-left: 20px;
          }
        }

        &:last-child {
          padding-right: 25px;
          @include media-breakpoint-down(sm) {
            padding-right: 20px;
          }
        }
      }
    }

    &.without-header {
      tr {
        &:first-child {
          td {
            border-top: none;
          }
        }
      }
    }
  }
}

.latest-activity-card {
  .card-body {
    padding-top: 0;

    .latest-update-box {
      position: relative;

      .p-t-20 {
        @include media-breakpoint-down(sm) {
          padding-top: 0;
        }
      }

      .p-b-30 {
        @include media-breakpoint-down(sm) {
          padding-bottom: 15px;
        }
      }

      .update-meta {
        z-index: 2;
        min-width: 170px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &:after {
          content: '';
          position: absolute;
          border-left: 2px dashed var(--bs-border-color);
          bottom: -10px;
          width: 2px;
          top: -20px;
          right: 32px;
          z-index: 1;
        }
        .update-icon {
          width: 40px;
          height: 40px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          text-align: center;
          font-size: 16px;
          background: var(--bs-card-bg);
          margin-left: 10px;
          position: relative;
          z-index: 5;
          i {
            font-size: 24px;
          }
        }
        @media only screen and (max-width: 575px) {
          z-index: 2;
          min-width: 100%;
          flex-direction: row-reverse;
          justify-content: flex-end;
          margin-bottom: 6px;
          border-top: 1px solid #f1f1f1;
          padding-top: 15px;
          &::after {
            display: none;
          }
          .update-icon {
            margin-left: 0;
            margin-right: 10px;
          }
        }
      }

      .row {
        @include media-breakpoint-down(sm) {
          margin-inline: 0;
        }
        > div {
          @include media-breakpoint-down(sm) {
            padding-inline: 0;
          }
        }
        &:first-child {
          .update-meta {
            border-top: 0;
            padding-top: 0;
          }
        }
      }
    }
  }
}

// ============================
//    10. Widget css end
// ============================
